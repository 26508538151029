import React from 'react';
import { useQuery } from 'react-query';
import { getReleases } from '../../../modules/catalog/core/_requests';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { DisplayArtistName } from '../../../modules/catalog/artist/DisplayArtistName';
import { DateTime } from 'luxon';
import { ReleaseUpcomingBadge } from '../../../modules/catalog/release/ReleaseUpcomingBadge';
import { Link } from 'react-router-dom';

type Props = {
  className: string;
};

const LastTracksWidget: React.FC<Props> = ({ className }) => {
  const {
    data: releases,
    error,
    isFetching,
    status
  } = useQuery(
    ['releases-5'],
    async () => {
      const { data: releases } = await getReleases(0, 5);
      return releases;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  );

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-gray-900">Next releases</h3>
      </div>

      <div className="card-body pt-2">
        {releases &&
          releases.results.map((release, index) => (
            <div className="d-flex align-items-center mb-7" key={release.id}>
              <div className="symbol symbol-50px me-5">
                {release.artwork ? (
                  <img src={release.artwork.public_url} className="" alt="" />
                ) : (
                  <>
                    <div className="symbol-label fs-2 fw-semibold text-warning">X</div>
                  </>
                )}
              </div>
              <div className="flex-grow-1">
                <Link
                  to={'/manager/catalog/releases/' + release.id}
                  className="text-gray-900 fw-bold text-hover-primary fs-6">
                  <DisplayArtistName artists={release.artists} /> - {release.name}
                </Link>
                {release.release_date && (
                  <span className="text-muted d-block fw-semibold">
                    Released on {DateTime.fromISO(release.release_date!).toLocaleString()}
                  </span>
                )}
                <ReleaseUpcomingBadge release={release} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export { LastTracksWidget };
