import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/manager/dashboard/DashboardWrapper';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { ChooseOrganizationPage } from '../pages/manager/organization/ChooseOrganizationPage';
import ListCatalogPage from '../pages/manager/catalog/ListCatalogPage';
import ListArtistPage from '../pages/manager/catalog/ListArtistPage';
import ViewReleaseWrapper from '../pages/manager/catalog/releases/ViewReleaseWrapper';
import ViewReleaseOverviewPage from '../pages/manager/catalog/releases/ViewReleaseOverviewPage';
import ViewReleaseInformationsPage from '../pages/manager/catalog/releases/ViewReleaseInformationsPage';
import ViewReleaseFanlinkConfigurationPage from '../pages/manager/catalog/releases/ViewReleaseFanlinkConfigurationPage';
import ViewReleaseTracksPage from '../pages/manager/catalog/releases/ViewReleaseTracksPage';
import CreateReleaseWrapper from '../pages/manager/catalog/releases/CreateReleaseWrapper';
import CreateReleaseStep1Page from '../pages/manager/catalog/releases/CreateReleaseStep1Page';
import CreateReleaseStep2Page from '../pages/manager/catalog/releases/CreateReleaseStep2Page';
import ViewReleaseFileManagerPage from '../pages/manager/catalog/releases/ViewReleaseFileManagerPage';
import AccountSettingsWrapper from '../pages/manager/account/AccountSettingsWrapper';
import AccountSettingsInformationsPage from '../pages/manager/account/AccountSettingsInformationsPage';
import AccountSettingsSecurityPage from '../pages/manager/account/AccountSettingsSecurityPage';
import OrganizationSettingsInformationPage from '../pages/manager/organization/OrganizationSettingsInformationPage';
import OrganizationSettingsWrapper from '../pages/manager/organization/OrganizationSettingsWrapper';

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route
        path="/choose-organization"
        element={
          <>
            <ChooseOrganizationPage />
          </>
        }
      />
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="catalog" element={<Navigate to={'/manager/catalog/releases'} />} />
        <Route path="catalog/artists" element={<ListArtistPage />} />
        <Route path="catalog/releases" element={<ListCatalogPage />} />
        <Route path="catalog/release/" element={<CreateReleaseWrapper />}>
          <Route path="create/:releaseId/tracks" element={<CreateReleaseStep2Page />} />
          <Route path="create/*" element={<CreateReleaseStep1Page />} />
        </Route>
        <Route path="catalog/releases/:releaseId" element={<ViewReleaseWrapper />}>
          <Route path="overview/" element={<ViewReleaseOverviewPage />} />
          <Route path="informations/" element={<ViewReleaseInformationsPage />} />
          <Route path="fanlink/" element={<ViewReleaseFanlinkConfigurationPage />} />
          <Route path="tracks/" element={<ViewReleaseTracksPage />} />
          <Route path="files/" element={<ViewReleaseFileManagerPage />} />

          <Route path="" element={<Navigate to="overview" />} />
        </Route>
        <Route path="account/" element={<AccountSettingsWrapper />}>
          <Route path="informations/" element={<AccountSettingsInformationsPage />} />
          <Route path="security/" element={<AccountSettingsSecurityPage />} />

          <Route path="" element={<Navigate to="/account/informations" />} />
        </Route>

        <Route path="organization/" element={<OrganizationSettingsWrapper />}>
          <Route path="informations/" element={<OrganizationSettingsInformationPage />} />
          <Route path="users/" element={<OrganizationSettingsInformationPage />} />
          <Route path="subscription/" element={<OrganizationSettingsInformationPage />} />
          <Route path="invoices/" element={<OrganizationSettingsInformationPage />} />

          {/* <Route path="accounting/" element={<AccountSettingsInformations />} /> */}

          <Route path="" element={<Navigate to="/account/informations" />} />
        </Route>

        {/* <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
