import { Link, Outlet, useLocation } from 'react-router-dom';
import { Content } from '../../../../_metronic/layout/components/Content';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { PageTitle } from '../../../../_metronic/layout/core';
import { KTIcon, checkIsActive } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import { useOrganization } from '../../../modules/organization/core/Organization';

const OrganizationSettingsWrapper = function () {
  const { currentOrganization } = useOrganization();
  const location = useLocation();

  return (
    <>
      <PageTitle breadcrumbs={[]}>Organization settings</PageTitle>
      <Toolbar />
      <Content>
        <div className="row">
          <div className="col-xs-12 col-md-4 col-xl-3 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col text-center">
                    <span className="text-gray-800 fs-4 fw-bolder mb-2">
                      {currentOrganization?.name}
                    </span>
                  </div>
                </div>
                {/* <div className="d-flex">
                  {/* <div className="flex-shrink-0 me-7">
                    <div className="symbol symbol-50px symbol-lg-120">
                      <div className="symbol-label bg-light">
                        {currentOrganization?.name &&
                          currentOrganization?.name[0] + currentOrganization?.name[1]}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bolder mb-2">
                      {currentOrganization?.name}
                    </a>
                  </div>
                </div> */}
                {/* Vertical tab navigation */}
                <ul className="mt-10 nav nav-tabs nav-pills flex-row border-0 flex-md-column me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px">
                  <li className="nav-item w-100 me-0 mb-md-4">
                    <Link
                      to={'/manager/organization/informations'}
                      className={
                        'nav-link w-100 btn btn-flex ' +
                        (checkIsActive(location.pathname, '/manager/organization/informations')
                          ? 'btn-light-primary'
                          : 'btn-bg-light btn-color-muted')
                      }>
                      <KTIcon iconName="profile-circle" className="fs-1 me-3" />
                      <div className="fs-4 fw-bold w-100 text-center">General Informations</div>
                    </Link>
                  </li>
                  <li className="nav-item w-100 me-0 mb-md-4">
                    <Link
                      to={'/manager/organization/users'}
                      className={
                        'nav-link w-100 btn btn-flex ' +
                        (checkIsActive(location.pathname, '/manager/organization/users')
                          ? 'btn-light-primary'
                          : 'btn-bg-light btn-color-muted')
                      }>
                      <KTIcon iconName="lock-3" className="fs-1 me-3" />
                      <div className="fs-4 fw-bold w-100 text-center">Users</div>
                    </Link>
                  </li>
                  <li className="nav-item w-100 me-0 mb-md-4">
                    <Link
                      to={'/manager/organization/subscription'}
                      className={
                        'nav-link w-100 btn btn-flex ' +
                        (checkIsActive(location.pathname, '/manager/organization/subscription')
                          ? 'btn-light-primary'
                          : 'btn-bg-light btn-color-muted')
                      }>
                      <KTIcon iconName="lock-3" className="fs-1 me-3" />
                      <div className="fs-4 fw-bold w-100 text-center">Subscription</div>
                    </Link>
                  </li>
                  <li className="nav-item w-100 me-0 mb-md-4">
                    <Link
                      to={'/manager/organization/invoices'}
                      className={
                        'nav-link w-100 btn btn-flex ' +
                        (checkIsActive(location.pathname, '/manager/organization/invoices')
                          ? 'btn-light-primary'
                          : 'btn-bg-light btn-color-muted')
                      }>
                      <KTIcon iconName="lock-3" className="fs-1 me-3" />
                      <div className="fs-4 fw-bold w-100 text-center">Invoices</div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col mt-xs-3">
            <Outlet />
          </div>
        </div>
      </Content>
    </>
  );
};

export default OrganizationSettingsWrapper;
