import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpotify,
  faSoundcloud,
  faApple,
  faAmazon,
  faBandcamp,
  faTiktok,
  faYoutube,
  faDeezer
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { ReleaseStoreLink } from '../core/_models';
import { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function StoreLinkButtons(props: { storeLinks: ReleaseStoreLink[] }) {
  function getStoreIcon(storeCode: string) {
    switch (storeCode) {
      case 'spotify':
        return faSpotify;
      case 'soundcloud':
        return faSoundcloud;
      case 'itunes':
        return faApple;
      case 'apple_music':
        return faApple;
      case 'amazon':
        return faAmazon;
      case 'amazon_music':
        return faAmazon;
      case 'bandcamp':
        return faBandcamp;
      case 'tiktok':
        return faTiktok;
      case 'youtube':
        return faYoutube;
      case 'deezer':
        return faDeezer;
      default:
        return faLink;
    }
  }

  return (
    <>
      {props.storeLinks.map((storeLink, index) => {
        return (
          <OverlayTrigger
            key={index}
            overlay={<Tooltip>Lien {storeLink.streaming_platform.name}</Tooltip>}
            placement="bottom"
            trigger={['focus', 'hover']}>
            <a href={storeLink.link} className="btn btn-sm btn-dark me-2 btn-icon" target="_blank">
              {/* Display the icon by switching the store code */}
              <FontAwesomeIcon icon={getStoreIcon(storeLink.streaming_platform.code!)} />
            </a>
          </OverlayTrigger>
        );
      })}
    </>
  );
}

export default StoreLinkButtons;
