import axios from 'axios';
import { UserModel } from '../../auth';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const USERS_URL = `${API_URL}/api/users/`;

export function patchUser(userId: string, user: Partial<UserModel>) {
  return axios.patch<UserModel>(USERS_URL + userId + '/', user);
}
