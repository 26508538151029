export interface Paginated<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export interface Address {}

export interface Store {
  code?: string;
  name: string;
}

export class StreamingPlatforms {
  // tslint:disable:variable-name

  public static spotify = {
    name: 'Spotify',
    key: 'spotify',
    icon: 'lm-icons spotify',
    color: '#66d36e',
    logoWhite: 'spotify_white.svg',
    logoBlack: 'spotify_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static soundcloud = {
    name: 'Soundcloud',
    key: 'soundcloud',
    icon: 'lm-icons soundcloud',
    color: '#ec5028',
    logoWhite: 'soundcloud_white.svg',
    logoBlack: 'soundcloud_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static apple_music = {
    name: 'Apple Music',
    key: 'apple_music',
    icon: 'lm-icons apple_music',
    color: '#282829',
    logoWhite: 'apple_music_white.svg',
    logoBlack: 'apple_music_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static itunes = {
    name: 'iTunes',
    key: 'itunes',
    icon: 'lm-icons itunes',
    color: '#638fe9',
    logoWhite: 'itunes_white.svg',
    logoBlack: 'itunes_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static deezer = {
    name: 'Deezer',
    key: 'deezer',
    icon: 'lm-icons deezer',
    color: '#a0396a',
    logoWhite: 'deezer_white.svg',
    logoBlack: 'deezer_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static youtube = {
    name: 'Youtube',
    key: 'youtube',
    icon: 'lm-icons youtube',
    color: '#ff0000',
    logoWhite: 'youtube_white.svg',
    logoBlack: 'youtube_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static tidal = {
    name: 'Tidal',
    key: 'tidal',
    icon: 'lm-icons tidal',
    color: '#3d2f58',
    logoWhite: 'tidal_white.svg',
    logoBlack: 'tidal_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static google_play = {
    name: 'Google Play',
    key: 'google_play',
    icon: 'lm-icons google_play',
    color: '#5ccffa',
    logoWhite: 'google_play_white.svg',
    logoBlack: 'google_play_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static fangate = {
    name: 'Fangate',
    key: 'fangate',
    icon: 'lm-icons fangate',
    color: '#000',
    logoWhite: 'fangate_white.png',
    logoBlack: 'fangate_black.png',
    actionText: 'Download',
    actionIcon: 'fa fa-download'
  };

  public static beatport = {
    name: 'Beatport',
    key: 'beatport',
    icon: 'lm-icons beatport',
    color: '#afd746',
    logoWhite: 'beatport_white.svg',
    logoBlack: 'beatport_black.svg',
    actionText: 'Buy',
    actionIcon: 'fa fa-shopping-basket'
  };

  public static amazon = {
    name: 'Amazon',
    key: 'amazon',
    icon: 'lm-icons amazon',
    color: '#FF9900',
    logoWhite: 'amazon_white.png',
    logoBlack: 'amazon_black.svg',
    actionText: 'Buy',
    actionIcon: 'fa fa-shopping-basket'
  };

  public static amazon_music = {
    name: 'Amazon Music',
    key: 'amazon_music',
    icon: 'lm-icons amazon_music',
    color: '#0DBFF5',
    logoWhite: 'amazon_music_white.svg',
    logoBlack: 'amazon_music_black.svg',
    actionText: 'Buy',
    actionIcon: 'fa fa-shopping-basket'
  };

  public static tiktok = {
    name: 'Tik Tok',
    key: 'tiktok',
    icon: 'lm-icons tiktok',
    color: '#696969',
    logoWhite: 'tiktok_white.png',
    logoBlack: 'tiktok_black.png',
    actionText: 'Use',
    actionIcon: 'fa fa-link'
  };

  public static other = {
    name: 'Other',
    key: 'other',
    icon: 'fa fa-music',
    color: '#696969',
    logoWhite: 'other_white.svg',
    logoBlack: 'other_black.svg',
    actionText: 'Play',
    actionIcon: 'fa fa-play'
  };

  public static bandcamp = {
    name: 'Bandcamp',
    key: 'bandcamp',
    icon: 'fa fa-music',
    color: '#1ca0c3',
    logoWhite: 'bandcamp_white.png',
    logoBlack: 'bandcamp_black.png',
    actionText: 'Buy',
    actionIcon: 'fa fa-shopping-basket'
  };

  public static juno = {
    name: 'Juno',
    key: 'juno',
    icon: 'fa fa-music',
    color: '#78a445',
    logoWhite: 'juno_white.png',
    logoBlack: 'juno_black.png',
    actionText: 'Buy',
    actionIcon: 'fa fa-shopping-basket'
  };

  public static platforms = [
    StreamingPlatforms.spotify,
    StreamingPlatforms.apple_music,
    StreamingPlatforms.beatport,
    StreamingPlatforms.deezer,
    StreamingPlatforms.fangate,
    StreamingPlatforms.amazon,
    StreamingPlatforms.amazon_music,
    StreamingPlatforms.bandcamp,
    StreamingPlatforms.google_play,
    StreamingPlatforms.itunes,
    StreamingPlatforms.tidal,
    StreamingPlatforms.youtube,
    StreamingPlatforms.tiktok,
    StreamingPlatforms.soundcloud,
    StreamingPlatforms.juno
  ];
}
