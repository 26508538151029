import axios from 'axios';
import { Organization } from './_models';
import { Paginated } from '../../../generics/_models';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const GET_ORGANIZATION = `${API_URL}/api/organizations/`;

export function getOrganizations() {
  return axios.get<Paginated<Organization>>(GET_ORGANIZATION + '?limit=100');
}

export function getOrganization(id: string) {
  return axios.get<Organization>(GET_ORGANIZATION + id + '/');
}
