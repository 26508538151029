import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { useAuth } from '../../auth';
import { WithChildren } from '../../../../_metronic/helpers';
import { Organization } from './_models';
import { getOrganization } from './_requests';
import { useNavigate } from 'react-router-dom';

type OrganizationContextProps = {
  currentOrganization: Organization | undefined;
  setCurrentOrganization: Dispatch<SetStateAction<Organization | undefined>>;
};

const initOrganizationContextPropsState = {
  currentOrganization: undefined,
  setCurrentOrganization: () => {}
};

const OrganizationContext = createContext<OrganizationContextProps>(
  initOrganizationContextPropsState
);

const useOrganization = () => {
  return useContext(OrganizationContext);
};

const OrganizationProvider: FC<WithChildren> = ({ children }) => {
  const [currentOrganization, setCurrentOrganization] = useState<Organization | undefined>();

  return (
    <OrganizationContext.Provider value={{ currentOrganization, setCurrentOrganization }}>
      {children}
    </OrganizationContext.Provider>
  );
};

const OrganizationInit: FC<WithChildren> = ({ children }) => {
  const { currentOrganization, setCurrentOrganization } = useOrganization();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const currentOrganizationId = localStorage.getItem('lm-organization-id');

    const requestOrganization = async (organizationId: string) => {
      try {
        if (!currentOrganization) {
          const { data } = await getOrganization(organizationId);
          if (data) {
            setCurrentOrganization(data);
          }
        }
      } catch (error) {
        console.error(error);
        if (currentOrganization) {
          localStorage.removeItem('lm-organization-id');
          // navigate('/manager/choose-organization');
        }
      } finally {
        setShowSplashScreen(false);
      }
    };

    if (currentOrganizationId) {
      requestOrganization(currentOrganizationId);
    } else {
      setShowSplashScreen(false);
      //navigate('/manager/choose-organization');
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { OrganizationProvider, OrganizationInit, useOrganization };
